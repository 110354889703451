import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { LayoutContext } from "../../hooks/layout-context";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Client } from "../../types/client";
import { Dropdown } from "react-bootstrap";
import useClient from "../../hooks/use-client";
import { UserContext } from "../../hooks/user-context";
import Confirmation from "../../components/layout/confirmation";
import { generate } from "../../lib/random-id-lib";
import { ToastsContext } from "../../hooks/toasts-context";

export default function ClientsList() {
  const { t } = useTranslation();
  const { layout, setLayout } = useContext(LayoutContext);
  const [rows, setRows] = useState<Array<Client>>([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [showTable, setShowTable] = useState(false);
  const { listing, exportClients, forceDeleteClient } = useClient();
  const { user, isLoading } = useContext(UserContext);
  const { toasts, setToasts } = useContext(ToastsContext);
  const [search, setSearch] = useState<string>("");
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [selectedClientDelete, setSelectedClientDelete] = useState<Client | null>(null);
  const [configLoaded, setConfigLoaded] = useState<boolean>(false);
  const [defaultPage, setDefaultPage] = useState<number>(1);

  React.useEffect(() => {
    layout.sideMenu = "clients";
    layout.breadcrumbs = [{ path: null, label: "Klienci" }];
    setLayout({ ...layout });
  }, []);

  const columns: Array<any> = [
    {
      name: t("Nazwa klienta"),
      key: "companyName",
      sortable: true,
      selector: (row: any) => <span>{row.companyName}</span>,
      right: false,
      wrap: true,
    },
    {
      name: t("Adres"),
      sortable: true,
      key: "city",
      selector: (row: any) => (
        <span>
          {row.street}, {row.zipCode} {row.city}
        </span>
      ),
      right: false,
      wrap: true,
    },
    {
      name: t("NIP"),
      sortable: true,
      key: "companyNip",
      selector: (row: any) => <span>{row.companyNip}</span>,
      right: false,
      wrap: true,
      maxWidth: "200px",
    },
    {
      name: "",
      selector: (row: any) =>
        !isLoading && user !== undefined && user !== null && user.permissions.indexOf("clients.add") !== -1 ? (
          <Dropdown>
            <Dropdown.Toggle as={"a"}>
              <i className="mdi mdi-dots-vertical"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item as={"div"}>
                <Link to={`/clients/edit/${row.id}`}>{t("Edytuj")}</Link>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setSelectedClientDelete(row)}>{t("Usuń")}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <></>
        ),
      right: true,
      maxWidth: "100px",
    },
  ];

  const handlePageChange = async (page: number) => {
    setLoading(true);
    await loadListing(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setLoading(true);
    await loadListing(page, newPerPage);
    setPerPage(newPerPage);
  };

  const loadListing = async (
    page: number,
    currentPerPage: number,
    sField: string | null = null,
    sDirection: string | null = null
  ) => {
    try {
      localStorage.setItem('table_clients', JSON.stringify({
        page, currentPerPage, sField, sDirection
      }));
      const result = await listing(page, currentPerPage, search, sField, sDirection);
      setRows(result.data);
      setTotalRows(result.page.totalElements);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
    if (!showTable) {
      setShowTable(true);
    }
  };

  React.useEffect(() => {
    (async () => {
      if (!configLoaded) {
        try {
          let tableConfig: any = localStorage.getItem('table_clients');
          if (tableConfig !== undefined && tableConfig !== null && tableConfig.length > 0) {
            tableConfig = JSON.parse(tableConfig);
            setSortDirection(tableConfig.sDirection);
            setSortField(tableConfig.sField);
            setPerPage(tableConfig.currentPerPage);
            setConfigLoaded(true);
            setDefaultPage(tableConfig.page);
            await loadListing(tableConfig.page, tableConfig.currentPerPage, tableConfig.sField, tableConfig.sDirection);
          } else {
            setConfigLoaded(true);
            await loadListing(1, perPage);
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        await loadListing(1, perPage);
      }
    })();
  }, [search]);

  const handleSort = async (column: any, direction: any) => {
    console.log("column", column, "direction", direction);
    setLoading(true);
    setSortField(column.key);
    setSortDirection(direction);
    await loadListing(1, perPage, column.key, direction);
    setLoading(false);
  };

  const deleteClient = async (id: any) => {
    console.log("deleteClient", id);
    if (selectedClientDelete !== undefined && selectedClientDelete !== null) {
      try {
        await forceDeleteClient(selectedClientDelete.id!!);
        toasts.push({
          title: t("Sukces"),
          content: t("Pojazd usunięty"),
          show: true,
          id: generate(32),
          type: "success",
        });
        setToasts([...toasts]);
      } catch (e) {
        console.log(e);
        toasts.push({
          title: t("Błąd"),
          content: t("Nie udało się usunąć"),
          show: true,
          id: generate(32),
          type: "danger",
        });
        setToasts([...toasts]);
      }

      await loadListing(1, perPage, sortField, sortDirection);
    }

    setSelectedClientDelete(null);
  };

  return (
    <div className="row mb-5">
      <div className="col-12">
        <div className="card shadow">
          <div className="card-body">
            <div className="row mb-4">
              <div className="col-sm-5">
                {!isLoading && user !== undefined && user !== null && user.permissions.indexOf("clients.add") !== -1 ? (
                  <Link to={"/clients/add"} className="btn btn-primary mb-2">
                    <i className="mdi mdi-plus-circle me-2"></i>
                    {t("Dodaj firmę")}
                  </Link>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-sm-7">
                <div className="text-sm-end">
                  <button type="button" className="btn btn-light mb-2 me-1" onClick={exportClients}>
                    {t("Exportuj")}
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 mb-4"></div>
              <div className="col-sm-6">
                <div className="data-tables-filter text-end">
                  <label>
                    {t("Szukaj")}:
                    <input
                      type="search"
                      className="form-control form-control-sm"
                      placeholder=""
                      onChange={(e) => setSearch(e.target.value)}
                      value={search || ""}
                      name={"search"}
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              {showTable && configLoaded ? (
                <DataTable
                  columns={columns}
                  data={rows}
                  striped={false}
                  sortServer
                  onSort={handleSort}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  noDataComponent={<div className={"p5"}>{t("Brak firm do wyświetlenia")}</div>}
                  paginationComponentOptions={{
                    rowsPerPageText: t("Wyświetl klientów na stronę:"),
                    rangeSeparatorText: t("of"),
                    noRowsPerPage: false,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: t("All"),
                  }}
                  paginationDefaultPage={defaultPage}
                  paginationPerPage={perPage}
                  paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30, 60, 90, 120, 150]}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                />
              ) : (
                <></>
              )}
              <Confirmation
                showConfirmation={selectedClientDelete !== null}
                onClose={() => setSelectedClientDelete(null)}
                onConfirm={() => deleteClient(selectedClientDelete!!.id)}
                content={t("Czy na pewno chcesz usunąć klienta?")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
