import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { LayoutContext } from "../../hooks/layout-context";
import useLogout from "../../hooks/use-logout";
import styled from "@emotion/styled";
import { css, cx } from "@emotion/css";
import MenuListLink from "./side-menu/menu-list-link";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/use-auth";
import { UserContext } from "../../hooks/user-context";
import { version } from "../../../package.json";

export default function SideMenu({ drawerWidth }: any) {
  const { logoutUser } = useLogout();
  const { layout, setLayout } = useContext(LayoutContext);
  const { setUser } = useContext(UserContext);
  const { t, i18n } = useTranslation();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { user, isLoading } = useContext(UserContext);

  const handleLogout = async () => {
    try {
      await logoutUser();
      await logout();
      setUser(null);
      navigate("/");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <aside className={`aside-left${drawerWidth === 60 ? " smaller" : ""}`} style={{ width: `${drawerWidth}px` }}>
      <Link to={"/"} className="logo">
        <img className={"logo-svg logo-desktop"} src={"/svg/logo.svg"} />
        <img className={"logo-svg logo-mob"} src={"/svg/logo-small.svg"} />
      </Link>
      <div className="aside-container">
        <nav className="aside-nav">
          {!isLoading && user !== undefined && user !== null ? (
            <ul className="aside-nav">
              <li className="aside-nav-title">{t("Nawigacja")}</li>
              <MenuListLink
                icon={"mdi-home-outline"}
                label={t("Home")}
                to={"/"}
                drawerWidth={drawerWidth}
                currentPath={layout.sideMenu}
                pathName={"home"}
              />
              {user.permissions.indexOf("tuning") !== -1 ? (
                <MenuListLink
                  icon={"mdi-lightning-bolt"}
                  label={t("Tuningowane")}
                  to={"/tuning"}
                  drawerWidth={drawerWidth}
                  currentPath={layout.sideMenu}
                  pathName={"tuning"}
                />
              ) : (
                <></>
              )}
              {user.permissions.indexOf("clients") !== -1 ? (
                <MenuListLink
                  icon={"mdi-domain"}
                  label={t("Klienci")}
                  to={"/clients"}
                  drawerWidth={drawerWidth}
                  currentPath={layout.sideMenu}
                  pathName={"clients"}
                >
                  <div
                    className={`collapse${
                      layout.sideMenu === "clients" ||
                      (layout.expanded !== undefined &&
                        layout.expanded !== null &&
                        layout.expanded.indexOf("clients") !== -1)
                        ? " show"
                        : ""
                    }`}
                  >
                    <ul className="aside-nav-second-level">
                      {user.permissions.indexOf("clients") !== -1 ? (
                        <li>
                          <Link to={"/clients"}>
                            {drawerWidth === 260 ? (
                              <span>{t("Wszyscy klienci")}</span>
                            ) : (
                              <span className={"mdi mdi-format-list-bulleted"}></span>
                            )}
                          </Link>
                        </li>
                      ) : (
                        <></>
                      )}
                      {user.permissions.indexOf("clients.add") !== -1 ? (
                        <li>
                          <Link to={"/clients/add"}>
                            {drawerWidth === 260 ? (
                              <span>{t("Dodaj klienta")}</span>
                            ) : (
                              <span className={"mdi mdi-plus-circle"}></span>
                            )}
                          </Link>
                        </li>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </div>
                </MenuListLink>
              ) : (
                <></>
              )}
              {user.permissions.indexOf("cars") !== -1 ? (
                <MenuListLink
                  icon={"mdi-truck"}
                  label={t("Pojazdy")}
                  to={"/cars"}
                  drawerWidth={drawerWidth}
                  currentPath={layout.sideMenu}
                  pathName={"cars"}
                />
              ) : (
                <></>
              )}
              {user.permissions.indexOf("employee") !== -1 ? (
                <MenuListLink
                  icon={"mdi-account-multiple"}
                  label={t("Pracownicy")}
                  to={"/employee"}
                  drawerWidth={drawerWidth}
                  currentPath={layout.sideMenu}
                  pathName={"employee"}
                >
                  <div
                    className={`collapse${
                      layout.sideMenu === "employee" ||
                      (layout.expanded !== undefined &&
                        layout.expanded !== null &&
                        layout.expanded.indexOf("employee") !== -1)
                        ? " show"
                        : ""
                    }`}
                  >
                    <ul className="aside-nav-second-level">
                      {user.permissions.indexOf("employee") !== -1 ? (
                        <li>
                          <Link to={"/employee"}>
                            {drawerWidth === 260 ? (
                              <span>{t("Wszyscy pracownicy")}</span>
                            ) : (
                              <span className={"mdi mdi-format-list-bulleted"}></span>
                            )}
                          </Link>
                        </li>
                      ) : (
                        <></>
                      )}
                      {user.permissions.indexOf("employee.add") !== -1 ? (
                        <li>
                          <Link to={"/employee/add"}>
                            {drawerWidth === 260 ? (
                              <span>{t("Dodaj pracownika")}</span>
                            ) : (
                              <span className={"mdi mdi-plus-circle"}></span>
                            )}
                          </Link>
                        </li>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </div>
                </MenuListLink>
              ) : (
                <></>
              )}
              {user.permissions.indexOf("settings") !== -1 ? (
                <MenuListLink
                  icon={"mdi-cog-outline"}
                  label={t("Ustawienia")}
                  to={"/settings"}
                  drawerWidth={drawerWidth}
                  currentPath={layout.sideMenu}
                  pathName={"settings"}
                />
              ) : (
                <></>
              )}
              <MenuListLink
                icon={"mdi-logout"}
                label={t("Wyloguj")}
                drawerWidth={drawerWidth}
                currentPath={layout.sideMenu}
                pathName={"logout"}
                click={handleLogout}
              />
            </ul>
          ) : (
            <></>
          )}
        </nav>
      </div>
    </aside>
  );
}
