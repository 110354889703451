import {useContext} from "react";
import axios from "axios";
import {API_URL} from "../config";
import {getHeaderOptions} from "../lib/request-helper";
import {ShowLoaderContext} from "./show-loader-context";
import {Car, CarCreateResponse} from "../types/car";
import {FormInputCar} from "../types/forms";

export default function useCar() {
  const {setShowLoader} = useContext(ShowLoaderContext);

  const getDashboardStats = async (): Promise<any> => {
    setShowLoader(true);
    try {
      const result = await axios.get(`${API_URL}/car/dashboard/stats`, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const listing = async (
    page: number,
    perPage: number,
    mode = "all",
    search: string = "",
    sortField: string | null = null,
    sortDirection: string | null = null,
    startDate: any = null,
    endDate: any = null,
    company: any = null,
    withInvoices: boolean = false
  ): Promise<any> => {
    setShowLoader(true);
    try {
      let queryString = `page=${page}`;
      queryString += `&perPage=${perPage}`;
      queryString += `&mode=${mode}`;
      queryString += `&search=${search}`;
      queryString += `&sortProp=${sortField}`;
      queryString += `&sortDir=${sortDirection}`;
      queryString += `&startDate=${startDate}`;
      queryString += `&endDate=${endDate}`;
      queryString += `&company=${company}`;
      queryString += `&withInvoices=${withInvoices}`;

      const result = await axios.get(
        `${API_URL}/car?${queryString}`,
        getHeaderOptions()
      );
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const getById = async (id: string): Promise<Car> => {
    setShowLoader(true);
    try {
      const result = await axios.get(`${API_URL}/car/${id}`, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const createCar = async (inputData: FormInputCar): Promise<CarCreateResponse> => {
    setShowLoader(true);
    try {
      const result = await axios.post(`${API_URL}/car`, inputData, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const updateCar = async (id: string, inputData: FormInputCar | any): Promise<Car> => {
    setShowLoader(true);
    try {
      const result = await axios.put(`${API_URL}/car/${id}`, inputData, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const forceDeleteCar = async (id: string): Promise<any> => {
    setShowLoader(true);
    try {
      const result = await axios.delete(`${API_URL}/car/${id}`, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const takeOverCar = async (id: string): Promise<Car> => {
    setShowLoader(true);
    try {
      const result = await axios.put(`${API_URL}/car/take-over/${id}`, {}, getHeaderOptions());
      setShowLoader(false);
      return result.data;
    } catch (e: any) {
      console.log(e);
      setShowLoader(false);
      throw e;
    }
  };

  const postFormInNewTab = (mode = 'cars', ids: Array<string> = []) => {
    const mapForm = document.createElement("form");
    mapForm.classList.add("form-hidden");
    mapForm.target = "_blank";
    mapForm.method = "POST";
    mapForm.action = `${API_URL}/car/export/ids?mode=${mode}`;

    ids.forEach((id) => {
      const mapInput = document.createElement("input");
      mapInput.type = "text";
      mapInput.name = "ids[]";
      mapInput.value = id;

      mapForm.appendChild(mapInput);
    });

    document.body.appendChild(mapForm);

    mapForm.submit();
  };

  const exportCars = (mode = 'cars', ids: Array<string> = []): void => {
    if (ids.length === 0) {
      window.open(`${API_URL}/car/export?mode=${mode}`, "_blank")!!.focus();
    } else {
      postFormInNewTab(mode, ids);
    }
  };

  return {
    getDashboardStats,
    listing,
    getById,
    createCar,
    updateCar,
    forceDeleteCar,
    takeOverCar,
    exportCars,
  };
}
