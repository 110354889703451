import React, {createRef, useContext, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {LayoutContext} from "../hooks/layout-context";
import {Link} from "react-router-dom";
import {Car, SocketCarMessage} from "../types/car";
import useCar from "../hooks/use-car";
import {Dropdown, OverlayTrigger, Tooltip} from "react-bootstrap";
import DataTable from "react-data-table-component";
import SideBox from "../components/layout/side-box";
import NotesList from "../components/notes-list";
import {UserContext} from "../hooks/user-context";
import {SocketContext} from "../hooks/socket-context";
import CarFilesList from "./car-files-list";
import {generate} from "../lib/random-id-lib";
import {ToastsContext} from "../hooks/toasts-context";
import useAuth from "../hooks/use-auth";
import Confirmation from "./layout/confirmation";
import moment from "moment";
import DatePicker from "react-datepicker";
import {registerLocale, setDefaultLocale} from "react-datepicker";
import pl from "date-fns/locale/pl";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import {Client} from "../types/client";
import useClient from "../hooks/use-client";
import CarModificationsFixRevertTooltip from './forms/car-modifications-fix-revert-tooltip';

registerLocale("pl", pl);

interface CarsListingProps {
  asTuning: boolean;
}

interface SelectAllProps {
  selectionChanged: any;
}

const SelectAll = ({selectionChanged}: SelectAllProps) => {
  const [checked, setChecked] = useState<boolean>(false);

  const changeState = () => {
    selectionChanged(!checked);
    setChecked(!checked);
  };

  return (
    <div className="form-check form-check-with-overlay" onClick={changeState}>
      <div className="overlay"></div>
      <input type="checkbox" className="form-check-input dt-checkboxes" readOnly />
      <label className="form-check-label">&nbsp;</label>
    </div>
  );
};

export default function CarsListing({asTuning = false}: CarsListingProps) {
  const refSearchCompany = createRef<any>();
  const {t} = useTranslation();
  const {getProfile} = useAuth();
  const {layout, setLayout} = useContext(LayoutContext);
  const [rows, setRows] = useState<Array<Car>>([]);
  const rowsRef = useRef<Array<Car>>([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [showNotes, setShowNotes] = useState<Car | null>(null);
  const [showFiles, setShowFiles] = useState<Car | null>(null);
  const {listing, exportCars, takeOverCar, forceDeleteCar, updateCar} = useCar();
  const {user, isLoading} = useContext(UserContext);
  const socket = useContext(SocketContext);
  const [search, setSearch] = useState<string>("");
  const {toasts, setToasts} = useContext(ToastsContext);
  const [selectedCarDelete, setSelectedCarDelete] = useState<Car | null>(null);
  const [selectedCarStatus, setSelectedCarStatus] = useState<Car | null>(null);
  const selectedIdsRef = React.useRef<Array<string>>([]);
  const [selectedIds, setSelectedIds] = useState<Array<string>>([]);
  const [isPartner, setIsPartner] = useState<boolean>(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [withInvoices, setWithInvoices] = useState(false);
  const [isLoadingClients, setIsLoadingClients] = useState<boolean>(false);
  const [clients, setClients] = useState<Array<Client>>([]);
  const [clientsOptions, setClientsOptions] = useState<Array<Client>>([]);
  const {listingSimpleClients} = useClient();
  const [client, setClient] = useState<Client | null>(null);
  const [configLoaded, setConfigLoaded] = useState<boolean>(false);
  const [defaultPage, setDefaultPage] = useState<number>(1);

  const filterBy = () => true;

  React.useEffect(() => {
    selectedIdsRef.current = [];
  }, []);

  React.useEffect(() => {
    if (user !== undefined && user !== null) {
      if (user.positionName === "Partner") {
        setIsPartner(true);
      }
    }
  }, [user]);

  const changedSelection = (checked: boolean) => {
    if (checked) {
      rows.forEach((row) => {
        if (selectedIdsRef.current.indexOf(row.id!!) === -1) {
          selectedIdsRef.current.push(row.id!!);
        }
      });
      setSelectedIds([...selectedIdsRef.current]);
    } else {
      rows.forEach((row) => {
        if (selectedIdsRef.current.indexOf(row.id!!) !== -1) {
          selectedIdsRef.current.splice(selectedIdsRef.current.indexOf(row.id!!), 1);
        }
      });
      setSelectedIds([...selectedIdsRef.current]);
    }
  };

  const changeCheckboxState = (id: string) => {
    if (selectedIdsRef.current.indexOf(id) !== -1) {
      selectedIdsRef.current.splice(selectedIdsRef.current.indexOf(id), 1);
    } else {
      selectedIdsRef.current.push(id);
    }
    setSelectedIds([...selectedIdsRef.current]);
  };

  const colCheckbox = {
    name: <SelectAll selectionChanged={changedSelection} />,
    key: "selected",
    sortable: false,
    selector: (row: any) => (
      <div className="form-check">
        <input
          type="checkbox"
          className="form-check-input dt-checkboxes"
          readOnly
          onClick={() => changeCheckboxState(row.id!!)}
          checked={selectedIdsRef.current.indexOf(row.id!!) !== -1}
        />
        <label className="form-check-label">&nbsp;</label>
      </div>
    ),
    right: false,
    maxWidth: "50px",
    minWidth: "50px",
  };
  const colBrand = {
    name: t("Marka"),
    key: "carBrand",
    sortable: true,
    selector: (row: any) =>
      !isPartner || (isPartner && row.company === user.client) ? (
        <Link to={`/cars/edit/${row.id}`} className={"hoverable"}>
          {row.CarBrand.title} {row.CarModel.title}
        </Link>
      ) : (
        <span>
          {row.CarBrand.title} {row.CarModel.title}
        </span>
      ),
    right: false,
  };
  const colRegistrationNumber = {
    name: t("Numer rejestracyjny"),
    key: "registrationNumber",
    sortable: true,
    selector: (row: any) => <span>{row.registrationNumber}</span>,
    right: false,
  };
  const colVinNumber = {
    name: t("Numer VIN"),
    key: "vin",
    sortable: true,
    selector: (row: any) => <span>{row.vin}</span>,
    right: false,
  };
  const colModifications = {
    name: t("Modyfikacje"),
    selector: (row: any) => (
      <div className={"d-flex items-center justify-center"}>
        <CarModificationsFixRevertTooltip car={row} />
        {row.withTuning ? <img src={"/svg/tuning.svg"} className={"icon-tuning"} /> : <></>}
        {row.withLimitations ? <img src={"/svg/engine.svg"} className={"icon-tuning"} /> : <></>}
        {row.withAdBlue ? <img src={"/svg/adblue.svg"} className={"icon-tuning"} /> : <></>}
      </div>
    ),
    right: false,
    maxWidth: "200px",
  };
  const colClient = {
    name: t("Firma"),
    key: "companyName",
    sortable: true,
    selector: (row: any) => (
      <div className={"text-wrap"}>
        <span>
          {row.Company.companyName} ({row.Company.companyNip})
        </span>
      </div>
    ),
    right: false,
  };
  const colFiles = {
    name: t("Przesyłany plik"),
    selector: (row: any) =>
      !isPartner || (isPartner && user.client === row.company) ? (
        row.isUploading ? (
          <div className="progress">
            <div
              className={
                "progress-bar progress-bar-striped progress-bar-animated " +
                (row.progress < 10 ? "bg-danger" : row.progress >= 100 ? "bg-success" : "bg-warning")
              }
              role="progressbar"
              aria-label="Animated striped example"
              aria-valuenow={row.progress || 0}
              aria-valuemin={0}
              aria-valuemax={100}
              style={{width: (row.progress || 0) + "%"}}
            />
          </div>
        ) : (
          <div>
            <a className={"text-primary hoverable"} onClick={() => setShowFiles(row)}>
              {t("Pliki")}: {row.carFiles.length || 0}
            </a>
          </div>
        )
      ) : (
        <></>
      ),
    right: false,
    wrap: false,
  };

  const employeeContent = (row: any) => {
    return row.CurrentEmployee !== undefined && row.CurrentEmployee !== null && row.CurrentEmployee.id !== null ? (
      row.CurrentEmployee.pathThumb !== undefined && row.CurrentEmployee.pathThumb !== null ? (
        <span className={"user-avatar"}>
          <img src={row.CurrentEmployee.pathThumb} />
        </span>
      ) : (
        <span className={"user-avatar"}>{row.CurrentEmployee.initial}</span>
      )
    ) : (
      <span>{t("Brak")}</span>
    );
  };

  const colEmployee = {
    name: t("Pracownik"),
    selector: (row: any) =>
      !isPartner && row.status !== "finished" && row.status !== "sended" ? (
        <OverlayTrigger overlay={<Tooltip>{t("Kliknij, aby przejąć")}</Tooltip>}>
          <div className={"text-wrap"} onClick={() => takeOver(row)}>
            {employeeContent(row)}
          </div>
        </OverlayTrigger>
      ) : (
        employeeContent(row)
      ),
    right: false,
  };

  const statusLabel = (row: any) => {
    return (
      <span
        className={`badge ${row.status === "added" ? "bg-danger" : ""}${row.status === "working" ? "bg-warning" : ""}${
          row.status === "finished" ? "bg-success" : ""
        }${row.status === "sended" ? "bg-dark" : ""}`}
      >
        {row.status === "added" ? "dodany" : ""}
        {row.status === "working" ? "w trakcie" : ""}
        {row.status === "finished" ? "zrobiony" : ""}
        {row.status === "sended" ? "wgrany" : ""}
      </span>
    );
  };
  const colLastNotes = {
    name: t("Najnowsza notatka"),
    selector: (row: any) =>
      !isPartner || (isPartner && user.client === row.company) ? (
        <span>{row.lastNoteDate !== undefined && row.lastNoteDate !== null ? row.lastNoteDate : "-"}</span>
      ) : (
        <span>-</span>
      ),
    right: false,
  };
  const colStatus = {
    name: t("Status"),
    selector: (row: any) =>
      !isPartner ? (
        <OverlayTrigger overlay={<Tooltip>{t("Kliknij, aby zmienić")}</Tooltip>}>
          <div className={"text-wrap"} onClick={() => changeStatus(row, false)}>
            {statusLabel(row)}
          </div>
        </OverlayTrigger>
      ) : isPartner && user.client === row.company && row.status !== "sended" ? (
        <OverlayTrigger overlay={<Tooltip>{t("Kliknij, aby zmienić")}</Tooltip>}>
          <div className={"text-wrap"} onClick={() => setSelectedCarStatus(row)}>
            {statusLabel(row)}
          </div>
        </OverlayTrigger>
      ) : (
        <div className={"text-wrap"}>{statusLabel(row)}</div>
      ),
    right: false,
  };
  const colActions = {
    name: "",
    selector: (row: any) =>
      (isPartner && row.company === user.client) || !asTuning || !isPartner ? (
        <Dropdown>
          <Dropdown.Toggle as={"a"}>
            <i className="mdi mdi-dots-vertical"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {!isPartner || (isPartner && row.company === user.client) ? (
              <>
                <Dropdown.Item as={"div"}>
                  <Link to={`/cars/edit/${row.id}`}>{t("Edytuj")}</Link>
                </Dropdown.Item>
                <Dropdown.Item as={"div"}>
                  <Link to={`/cars/view/${row.id}`}>{t("Pokaż pojazd")}</Link>
                </Dropdown.Item>
              </>
            ) : (
              <></>
            )}
            {isPartner && asTuning && row.company === user.client ? (
              <Dropdown.Item onClick={() => setShowNotes(row)}>{t("Notatki")}</Dropdown.Item>
            ) : (
              <></>
            )}
            {!isPartner ? (
              <>
                <Dropdown.Item onClick={() => setShowFiles(row)}>{t("Wyślij plik")}</Dropdown.Item>
                {asTuning && row.status !== "finished" && row.status !== "sended" ? (
                  <>
                    <Dropdown.Item as={"div"} onClick={() => takeOver(row)}>
                      {t("Przejmij pojazd")}
                      {row.CurrentEmployee !== undefined &&
                      row.CurrentEmployee !== null &&
                      row.CurrentEmployee.id !== null
                        ? "(" + t("od") + ": " + row.CurrentEmployee.firstName + ")"
                        : ""}
                    </Dropdown.Item>
                  </>
                ) : (
                  <></>
                )}
                <Dropdown.Item onClick={() => setShowNotes(row)}>{t("Notatki")}</Dropdown.Item>
                <Dropdown.Item onClick={() => setSelectedCarDelete(row)}>{t("Usuń")}</Dropdown.Item>
              </>
            ) : (
              <></>
            )}
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <></>
      ),
    right: true,
    maxWidth: "100px",
  };

  const changeStatus = async (car: Car, byPartner = false) => {
    let status = "added";
    if (car.status === "added") {
      status = "working";
    } else if (car.status === "working") {
      status = "finished";
    } else if (car.status === "finished") {
      status = "sended";
    } else if (car.status === "sended") {
      status = "working";
    }

    if (byPartner) {
      status = "sended";
    }

    try {
      await updateCar(car.id!!, {status});
      const index = rows.findIndex((it) => it.id === car.id);
      const profile = await getProfile();
      console.log("profile", profile);
      if (index !== -1) {
        rows[index].status = status;
        setRows([...rows]);
      }
      toasts.push({title: t("Sukces"), content: t("Dane zapisane"), show: true, id: generate(32), type: "success"});
      setToasts([...toasts]);
      setSelectedCarStatus(null);
      socket.emit("message", {car: rows[index].id, status: status, room: "all"} as SocketCarMessage);
    } catch (e: any) {
      console.log(e);
    }
  };

  const takeOver = async (car: Car) => {
    try {
      await takeOverCar(car.id!!);
      const index = rows.findIndex((it) => it.id === car.id);
      const profile = await getProfile();
      console.log("profile", profile);
      if (index !== -1) {
        rows[index].CurrentEmployee = {
          id: user.id,
          firstName: profile.firstName,
          initial: profile.initial,
          path: profile.path,
          pathThumb: profile.pathThumb,
        };
        setRows([...rows]);
      }
      toasts.push({title: t("Sukces"), content: t("Dane zapisane"), show: true, id: generate(32), type: "success"});
      setToasts([...toasts]);
      socket.emit("message", {
        car: rows[index].id,
        CurrentEmployee: rows[index].CurrentEmployee,
        room: "all",
      } as SocketCarMessage);
    } catch (e: any) {
      console.log(e);
    }
  };

  const colCreatedAt = {
    name: t("Dodano"),
    selector: (row: any) => <div className={"text-wrap"}>{row.createdAt}</div>,
    sortable: true,
    right: false,
  };

  const coInvoices = {
    name: t("Faktury"),
    selector: (row: any) => (
      <div className={"text-wrap"}>
        {row.invoices !== undefined && row.invoices !== null && row.invoices.length > 0 ? (
          row.invoices.map((inv: any, invIndex: number) => (
            <div key={`TableRowInvoice${invIndex}`}>{inv.invoice}</div>
          ))
        ) : <></>}
      </div>
    ),
    right: false,
  };

  const columns: Array<any> = asTuning
    ? isPartner
      ? [colCreatedAt, colBrand, colModifications, colFiles, colEmployee, colLastNotes, colStatus, colActions]
      : [
        colCheckbox,
        coInvoices,
        colCreatedAt,
        colBrand,
        colRegistrationNumber,
        colModifications,
        colFiles,
        colEmployee,
        colLastNotes,
        colStatus,
        colActions,
      ]
    : isPartner
      ? [colCreatedAt, colBrand, colRegistrationNumber, colVinNumber, colModifications, colClient, colActions]
      : [
        colCheckbox,
        coInvoices,
        colCreatedAt,
        colBrand,
        colRegistrationNumber,
        colVinNumber,
        colModifications,
        colClient,
        colActions,
      ];

  const handleMessage = async (message: SocketCarMessage) => {
    console.log("handleMessage", message);
    if (message !== undefined && message !== null) {
      if (
        message.CurrentEmployee !== undefined &&
        message.CurrentEmployee !== null &&
        message.car !== undefined &&
        message.car !== null
      ) {
        const found: Car | undefined = rowsRef.current.find((it) => it.id === message.car);
        if (found !== undefined && found !== null) {
          const index: number = rowsRef.current.findIndex((it) => it.id === message.car);
          found.CurrentEmployee = message.CurrentEmployee;
          const temp = [...rowsRef.current];
          temp[index] = found;

          setRows([...temp]);
          rowsRef.current = [...temp];
        }
      } else if (
        message.status !== undefined &&
        message.status !== null &&
        message.car !== undefined &&
        message.car !== null
      ) {
        const found: Car | undefined = rowsRef.current.find((it) => it.id === message.car);
        if (found !== undefined && found !== null) {
          const index: number = rowsRef.current.findIndex((it) => it.id === message.car);
          found.status = message.status;
          const temp = [...rowsRef.current];
          temp[index] = found;

          setRows([...temp]);
          rowsRef.current = [...temp];
        }
      } else if (message.car !== undefined && message.car !== null) {
        const found: Car | undefined = rowsRef.current.find((it) => it.id === message.car);
        if (found !== undefined && found !== null) {
          if (message.progress !== undefined && message.progress !== null) {
            const index: number = rowsRef.current.findIndex((it) => it.id === message.car);
            found.progress = message.progress;
            found.isUploading = true;

            const temp = [...rowsRef.current];
            temp[index] = found;

            setRows([...temp]);
            rowsRef.current = [...temp];

            if (message.progress >= 100) {
              await loadListing(
                1,
                perPage,
                sortField,
                sortDirection,
                startDate,
                endDate,
                client !== null ? client.id : null
              );
            }
          }
        }
      }
    }
  };

  React.useEffect(() => {
    if (asTuning) {
      socket.on("message", handleMessage);
    }

    return () => {
      if (asTuning) {
        socket.off("message", handleMessage);
      }
    };
  }, []);

  React.useEffect(() => {
    (async () => {
      if (!configLoaded) {
        try {
          let tableConfig: any = localStorage.getItem(`table_cars_${asTuning ? 'tuning' : 'cars'}`);
          if (tableConfig !== undefined && tableConfig !== null && tableConfig.length > 0) {
            tableConfig = JSON.parse(tableConfig);
            setSortDirection(tableConfig.sDirection);
            setSortField(tableConfig.sField);
            setPerPage(tableConfig.currentPerPage);
            setConfigLoaded(true);
            setDefaultPage(tableConfig.page);
            await loadListing(tableConfig.page, tableConfig.currentPerPage, tableConfig.sField, tableConfig.sDirection, startDate, endDate, client !== null ? client.id : null);
          } else {
            setConfigLoaded(true);
            await loadListing(1, perPage, sortField, sortDirection, startDate, endDate, client !== null ? client.id : null);
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        await loadListing(1, perPage, sortField, sortDirection, startDate, endDate, client !== null ? client.id : null);
      }
    })();
  }, [search]);

  const handlePageChange = async (page: number) => {
    setLoading(true);
    setCurrentPage(page);
    await loadListing(page, perPage, sortField, sortDirection, startDate, endDate, client !== null ? client.id : null);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setLoading(true);
    setCurrentPage(page);
    await loadListing(
      page,
      newPerPage,
      sortField,
      sortDirection,
      startDate,
      endDate,
      client != null ? client.id : null
    );
    setPerPage(newPerPage);
  };

  const loadListing = async (
    page: number,
    currentPerPage: number,
    sField: string | null = null,
    sDirection: string | null = null,
    fStartDate: any = null,
    fEndDate: any = null,
    fCompany: any = null,
    withInvoices: boolean = false
  ) => {
    try {
      localStorage.setItem(`table_cars_${asTuning ? 'tuning' : 'cars'}`, JSON.stringify({
        page, currentPerPage, sField, sDirection
      }));
      const result = await listing(
        page,
        currentPerPage,
        asTuning ? "tuning" : "cars",
        search,
        sField,
        sDirection,
        fStartDate !== null ? moment(fStartDate).format("YYYY-MM-DD") : null,
        fEndDate !== null ? moment(fEndDate).format("YYYY-MM-DD") : null,
        fCompany,
        withInvoices
      );
      setRows(result.data);
      rowsRef.current = result.data;
      setTotalRows(result.page.totalElements);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
    if (!showTable) {
      setShowTable(true);
    }
  };

  const afterFileUpload = async () => {
    await loadListing(
      currentPage,
      perPage,
      sortField,
      sortDirection,
      startDate,
      endDate,
      client !== null ? client.id : null
    );
    if (showFiles !== undefined && showFiles !== null) {
      const found = rowsRef.current.find((it) => it.id === showFiles.id);
      if (found !== undefined && found !== null) {
        setShowFiles({...found});
      }
    }
    toasts.push({title: t("Sukces"), content: t("Pliki przesłane"), show: true, id: generate(32), type: "success"});
    setToasts([...toasts]);
    handleSideBoxClose();
  };

  const handleSideBoxClose = () => {
    setShowNotes(null);
    setShowFiles(null);
  };

  const handleSort = async (column: any, direction: any) => {
    console.log("column", column, "direction", direction);
    setLoading(true);
    setSortField(column.key);
    setSortDirection(direction);
    await loadListing(1, perPage, column.key, direction, startDate, endDate, client !== null ? client.id : null);
    setLoading(false);
  };

  const handleFilter = async (argStartDate = null, argEndDate = null, argClient = null, forceFilterClear = false, argWithInvoices = null) => {
    console.log("handleFilter", startDate, endDate, client);
    console.log("handleFilterArgs", argStartDate, argEndDate, argClient);

    setLoading(true);
    await loadListing(
      1,
      perPage,
      sortField,
      sortDirection,
      forceFilterClear ? null : argStartDate !== null ? argStartDate : startDate,
      forceFilterClear ? null : argEndDate !== null ? argEndDate : endDate,
      forceFilterClear ? null : argClient !== null ? argClient : client !== null ? client.id : null,
      forceFilterClear ? false : argWithInvoices !== null ? argWithInvoices : withInvoices,
    );
    setLoading(false);
  };

  const deleteCar = async (id: any) => {
    console.log("deleteCar", id);
    if (selectedCarDelete !== undefined && selectedCarDelete !== null) {
      try {
        await forceDeleteCar(selectedCarDelete.id!!);
        toasts.push({
          title: t("Sukces"),
          content: t("Pojazd usunięty"),
          show: true,
          id: generate(32),
          type: "success",
        });
        setToasts([...toasts]);
      } catch (e) {
        console.log(e);
        toasts.push({
          title: t("Błąd"),
          content: t("Nie udało się usunąć"),
          show: true,
          id: generate(32),
          type: "danger",
        });
        setToasts([...toasts]);
      }

      await loadListing(1, perPage, sortField, sortDirection, startDate, endDate, client !== null ? client.id : null);
    }

    setSelectedCarDelete(null);
  };

  const onNoteCreate = () => {
    if (showNotes !== undefined && showNotes !== null) {
      const index = rowsRef.current.findIndex((it) => it.id === showNotes.id);
      if (index > -1) {
        rowsRef.current[index].lastNoteDate = moment().format("YYYY-MM-DD HH:mm:ss");
        setRows(rowsRef.current);
      }
    }
  };

  const handleSearchClients = async (query: string) => {
    setIsLoadingClients(true);

    try {
      const rows = await listingSimpleClients(query);
      rows.forEach((row: any) => {
        row.title = `${row.companyName}, ${row.companyNip}`;
      });
      setClientsOptions(rows);
    } catch (e: any) {
      console.log(e);
    }

    setIsLoadingClients(false);
  };

  const changedClient = (e: any) => {
    if (e !== undefined && e !== null && e.length > 0) {
      console.log("changed-client", e[0]);
      setClient(e[0]);
      handleFilter(startDate, endDate, e[0].id);
    }
  };

  const conditionalRowStyles = [
    {
      when: (row: any) => row.invoices !== undefined && row.invoices !== null && row.invoices.length > 0,
      style: {
        backgroundColor: 'rgb(248, 249, 250)',
      },
    },
  ];

  return (
    <div className="row mb-5">
      <div className="col-12">
        <div className="card shadow">
          <div className="card-body">
            <div className="row mb-5">
              <div className="col-sm-5">
                {!isLoading && user !== undefined && user !== null && user.permissions.indexOf("cars.add") !== -1 ? (
                  <Link to={!asTuning ? "/cars/add" : "/tuning/add"} className="btn btn-primary mb-2">
                    <i className="mdi mdi-plus-circle me-2"></i>
                    {t(!asTuning ? "Dodaj pojazd" : "Tuning")}
                  </Link>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-sm-7">
                {!isPartner ? (
                  <div className="text-sm-end">
                    <button
                      type="button"
                      className="btn btn-light mb-2 me-1"
                      onClick={() => exportCars(asTuning ? "tuning" : "cars", selectedIds)}
                    >
                      {t("Exportuj")}
                    </button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 mb-4 d-flex align-items-center justify-content-between">
                <div className={'d-flex align-items-center'}>
                  <span className={"pr-label"}>{t("Od")}</span>
                  <DatePicker
                    className="form-control form-control-sm input-date"
                    selected={startDate}
                    onChange={(date: any) => {
                      setStartDate(date);
                      handleFilter(date, null, null);
                    }}
                    locale="pl"
                    dateFormat="P"
                  />
                  <span className={"pr-label"}>{t("do")}</span>
                  <DatePicker
                    className="form-control form-control-sm input-date"
                    selected={endDate}
                    onChange={(date: any) => {
                      setEndDate(date);
                      handleFilter(null, date, null);
                    }}
                    locale="pl"
                    dateFormat="P"
                  />
                  <AsyncTypeahead
                    ref={refSearchCompany}
                    filterBy={filterBy}
                    id="async-clients"
                    isLoading={isLoadingClients}
                    labelKey="title"
                    minLength={2}
                    onSearch={handleSearchClients}
                    onChange={(e) => changedClient(e)}
                    options={clientsOptions}
                    placeholder={t("Firma")}
                    renderInput={({inputRef, referenceElementRef, ...inputProps}) => (
                      <input
                        {...inputProps}
                        ref={(node) => {
                          inputRef(node);
                          referenceElementRef(node);
                        }}
                        type="text"
                        className={`rbt-input-main form-control rbt-input form-control-sm cars-search-input`}
                      />
                    )}
                    renderMenuItemChildren={(option: any) => (
                      <>
                        <span>
                          {option.companyName}, {option.companyNip}
                        </span>
                      </>
                    )}
                  />
                  <div className="form-check form-switch d-flex align-content-center ml-small">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="withInvoices"
                      onChange={(e: any) => {
                        setWithInvoices(e.target.checked);
                        handleFilter(null, null, null, false, e.target.checked);
                      }}
                    />
                    <label className="form-check-label text-no-wrap ml-small" htmlFor="withInvoices">
                      {t("z fakturami")}
                    </label>
                  </div>
                  <button
                    type="button"
                    className="btn btn-success mb-0 ms-2 btn-sm"
                    onClick={() => handleFilter(null, null, null)}
                  >
                    {t("Filtruj")}
                  </button>
                  <button
                    type="button"
                    className="btn btn-light mb-0 ms-2 btn-sm"
                    onClick={() => {
                      setStartDate(null);
                      setEndDate(null);
                      setClient(null);
                      console.log(refSearchCompany.current);
                      refSearchCompany.current.clear();
                      handleFilter(null, null, null, true);
                    }}
                  >
                    {t("Wyczyść")}
                  </button>
                </div>
                <div>
                  <div className="data-tables-filter text-end">
                    <label>
                      {t("Szukaj")}:
                      <input
                        type="search"
                        className="form-control form-control-sm"
                        placeholder=""
                        onChange={(e) => setSearch(e.target.value)}
                        value={search || ""}
                        name={"search"}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              {showTable && configLoaded ? (
                <DataTable
                  columns={columns}
                  data={rowsRef.current as any}
                  striped={false}
                  sortServer
                  onSort={handleSort}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30, 60, 90, 120, 150]}
                  noDataComponent={
                    <div className={"p5"}>
                      {t(asTuning ? "Brak tuningowanych do wyświetlenia" : "Brak pojazdów do wyświetlenia")}
                    </div>
                  }
                  paginationComponentOptions={{
                    rowsPerPageText: t(asTuning ? "Wyświetl tuningowanych na stronę" : "Wyświetl pojazdów na stronę"),
                    rangeSeparatorText: t("of"),
                    noRowsPerPage: false,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: t("All"),
                  }}
                  conditionalRowStyles={conditionalRowStyles}
                  paginationDefaultPage={defaultPage}
                  paginationPerPage={perPage}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      {showNotes !== null ? (
        <SideBox onClose={handleSideBoxClose}>
          <NotesList car={showNotes} onNoteCreate={onNoteCreate} />
        </SideBox>
      ) : (
        <></>
      )}
      {showFiles !== null ? (
        <SideBox onClose={handleSideBoxClose}>
          <CarFilesList car={showFiles} afterFileUpload={afterFileUpload} />
        </SideBox>
      ) : (
        <></>
      )}
      <Confirmation
        showConfirmation={selectedCarDelete !== null}
        onClose={() => setSelectedCarDelete(null)}
        onConfirm={() => deleteCar(selectedCarDelete!!.id)}
        content={t("Czy na pewno chcesz usunąć pojazd?")}
      />
      <Confirmation
        showConfirmation={selectedCarStatus !== null}
        onClose={() => setSelectedCarStatus(null)}
        onConfirm={() => changeStatus(selectedCarStatus!!, true)}
        content={t("Czy na pewno chcesz zmienić status pojazdu na 'wgrany'?")}
      />
    </div>
  );
}
