import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {Link, useNavigate} from "react-router-dom";
import {ToastsContext} from "../../hooks/toasts-context";
import {generate} from "../../lib/random-id-lib";
import {Car, CarBrand, CarCreateResponse, CarGearBox, CarModel, CarType, SocketCarMessage} from "../../types/car";
import useCar from "../../hooks/use-car";
import {FormInputCar} from "../../types/forms";
import {Client} from "../../types/client";
import useCarType from "../../hooks/use-car-type";
import useCarBrand from "../../hooks/use-car-brand";
import useCarModel from "../../hooks/use-car-model";
import useCarGearBox from "../../hooks/use-car-gear-box";
import useClient from "../../hooks/use-client";
import {useDropzone} from "react-dropzone";
import axios from "axios";
import {API_URL} from "../../config";
import {getHeaderOptions} from "../../lib/request-helper";
import {socket} from "../../hooks/socket-context";
import moment from "moment";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import {UserContext} from "../../hooks/user-context";

interface CarFormProps {
  car?: Car | null;
  id?: string | null;
  asTuning: boolean;
}

export default function CarForm({id = null, car = null, asTuning = false}: CarFormProps) {
  const {t} = useTranslation();
  const {createCar, updateCar} = useCar();
  const {listingSimpleCarTypes} = useCarType();
  const {listingSimpleCarBrands} = useCarBrand();
  const {listingSimpleCarModels} = useCarModel();
  const {listingSimpleCarGearBoxes} = useCarGearBox();
  const {listingSimpleClients} = useClient();
  const navigate = useNavigate();
  const {
    setValue,
    register,
    handleSubmit,
    getValues,
    formState: {errors},
  } = useForm<FormInputCar>();
  const {toasts, setToasts} = useContext(ToastsContext);
  const [carTypes, setCarTypes] = useState<Array<CarType>>([]);
  const [carTypesOptions, setCarTypesOptions] = useState<Array<CarType>>([]);
  const [carBrands, setCarBrands] = useState<Array<CarBrand>>([]);
  const [carBrandsOptions, setCarBrandsOptions] = useState<Array<CarBrand>>([]);
  const [carModels, setCarModels] = useState<Array<CarModel>>([]);
  const [carModelsOptions, setCarModelsOptions] = useState<Array<CarModel>>([]);
  const [filteredCarModels, setFilteredCarModels] = useState<Array<CarModel>>([]);
  const [carGearBoxes, setCarGearBoxes] = useState<Array<CarGearBox>>([]);
  const [carGearBoxesOptions, setCarGearBoxesOptions] = useState<Array<CarGearBox>>([]);
  const [clients, setClients] = useState<Array<Client>>([]);
  const [clientsOptions, setClientsOptions] = useState<Array<Client>>([]);
  const [years, setYears] = useState<Array<number>>([]);
  const [yearsOptions, setYearsOptions] = useState<Array<any>>([]);
  const [isLoadingClients, setIsLoadingClients] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const {user} = useContext(UserContext);
  const [invoices, setInvoices] = useState<Array<any>>([]);

  const [filesBeforeUpload, setFilesBeforeUpload] = useState<Array<any>>([]);
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
    disabled: false,
    onDrop: (acceptedFiles) => {
      setFilesBeforeUpload([
        ...filesBeforeUpload.concat(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
              uploading: true,
              currentUploading: false,
            })
          )
        ),
      ]);
    },
  });

  React.useEffect(() => {
    console.log("errors ---", errors, "values", getValues());
  }, [errors]);

  React.useEffect(() => {
    const yrs = [];
    const maxYear = parseFloat(moment().format("YYYY"));
    for (let i = 1990; i <= maxYear; i++) {
      yrs.push(i);
    }
    setYears(yrs);
    setYearsOptions(yrs.map((it) => ({year: it.toString()})));
    (async () => {
      try {
        const rows = await listingSimpleClients();
        setClients(rows);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  React.useEffect(() => {
    register("company", {value: "", required: true});
    register("year", {value: null, required: true});
    register("carType", {value: "", required: true});
    register("carBrand", {value: "", required: true});
    register("carModel", {value: "", required: true});
    register("carGearBox", {value: "", required: true});

    setCompanyByPartner();
  }, []);

  React.useEffect(() => {
    if (car !== undefined && car !== null) {
      setValue("company", car?.company!!);
    }
  }, [clients]);

  React.useEffect(() => {
    (async () => {
      try {
        const rows = await listingSimpleCarTypes();
        setCarTypes(rows);
        setCarTypesOptions(rows);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  React.useEffect(() => {
    setValue("carType", car?.carType!!);
  }, [carTypes]);

  React.useEffect(() => {
    (async () => {
      try {
        const rows = await listingSimpleCarBrands();
        setCarBrands(rows);
        setCarBrandsOptions(rows);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  React.useEffect(() => {
    setValue("carBrand", car?.carBrand!!);
  }, [carBrands]);

  const setCompanyByPartner = () => {
    console.log("setCompanyByPartner --- user--- ", user);
    if (user !== undefined && user !== null) {
      if (user.positionName === "Partner") {
        setValue("company", user.client);
      }
    }
  };

  React.useEffect(() => {
    setCompanyByPartner();
  }, [user]);

  React.useEffect(() => {
    (async () => {
      try {
        const rows = await listingSimpleCarModels();
        setCarModels(rows);
        setCarModelsOptions([]);

        if (car !== undefined && car !== null) {
          const newValue: string = car.CarBrand!!.id!!;
          const res = rows.filter((it: any) => it.carBrand === newValue);
          setFilteredCarModels([...res]);
          setCarModelsOptions([...res]);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  React.useEffect(() => {
    setValue("carModel", car?.carModel!!);
  }, [carModels]);

  React.useEffect(() => {
    (async () => {
      try {
        const rows = await listingSimpleCarGearBoxes();
        setCarGearBoxes(rows);
        setCarGearBoxesOptions(rows);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  React.useEffect(() => {
    if (car !== undefined && car !== null) {
      (async () => {
        const rows = await listingSimpleClients(car.Company?.companyName);
        rows.forEach((row: any) => {
          row.title = `${row.companyName}, ${row.companyNip}`;
        });
        setClientsOptions(rows);
      })();
    }
  }, []);

  React.useEffect(() => {
    setValue("carGearBox", car?.carGearBox!!);
  }, [carGearBoxes]);

  React.useEffect(() => {
    if (car !== undefined && car !== null) {
      setFilteredCarModels([...carModels.filter((it) => it.carBrand === car.carBrand!!)]);
      setValue("carModel", car?.carModel!!);
    }
  }, [carBrands, carModels]);

  React.useEffect(() => {
    if (car !== undefined && car !== null) {
      const keys: Array<string> = Object.keys(getValues() as FormInputCar);
      for (let k of keys) {
        // @ts-ignore
        setValue(k as keyof FormInputCar, car[k as keyof Car]);
      }

      if (car.invoices !== undefined && car.invoices !== null && car.invoices.length > 0) {
        setInvoices([...car.invoices]);
      }
    }
  }, []);

  const uploadSingleFile = async (carId: string, index: number, filesCounter: number) => {
    const headers: any = getHeaderOptions();
    headers.onUploadProgress = (progressEvent: any) => {
      console.log(progressEvent.loaded);
      console.log(index, filesCounter);
      console.log(progressEvent);
      let value = (progressEvent.loaded * 100) / progressEvent.total / filesCounter + (100 / filesCounter) * index;

      if (index + 1 === filesCounter) {
        if (progressEvent.loaded === progressEvent.total) {
          value = 100;
        }
      }

      const content: SocketCarMessage = {
        car: carId,
        progress: value,
        room: "all",
      };
      socket.emit("message", content);
    };

    const formData = new FormData();
    formData.set("file", filesBeforeUpload[index], filesBeforeUpload[index].name);
    await axios.post(`${API_URL}/car/${carId}/upload`, formData, headers);
  };

  const addInvoice = () => {
    const values = getValues('invoice');
    if (values !== undefined && values !== null && values.length > 0) {
      invoices.push({invoice: values, toUpload: true});
      setInvoices([...invoices])
    }
    setValue('invoice', '');
  }

  const removeInvoice = (item: any, key: number) => {
    if (item.id !== undefined && item.id !== null) {
      invoices[key].toDelete = true;
      setInvoices([...invoices]);
    } else {
      invoices.splice(key, 1);
      setInvoices([...invoices]);
    }
  }

  const handleFileUploads = async (carId: string) => {
    if (filesBeforeUpload !== undefined && filesBeforeUpload !== null && filesBeforeUpload.length > 0) {
      const filesCounter = filesBeforeUpload.length;
      for (let i = 0; i < filesCounter; i++) {
        await uploadSingleFile(carId, i, filesCounter);
      }
    }
  };

  const handleLogin = async (data: any) => {
    data.invoices = invoices;
    console.log("data", data);
    if (!processing) {
      setProcessing(true);
      if (id !== undefined && id !== null) {
        try {
          await updateCar(id, data);
          toasts.push({
            title: t("Sukces"),
            content: t("Dane zapisane"),
            show: true,
            id: generate(32),
            type: "success",
          });
          setToasts([...toasts]);
          await handleFileUploads(id);
          setProcessing(false);
          navigate(!asTuning ? "/cars" : "/tuning");
        } catch (e) {
          console.log(e);
          toasts.push({
            title: t("Błąd"),
            content: t("Nie udało się zapisać"),
            show: true,
            id: generate(32),
            type: "danger",
          });
          setToasts([...toasts]);
          setProcessing(false);
        }
      } else {
        try {
          const response: CarCreateResponse = await createCar(data);
          toasts.push({
            title: t("Sukces"),
            content: t("Dane zapisane"),
            show: true,
            id: generate(32),
            type: "success",
          });
          setToasts([...toasts]);
          await handleFileUploads(response.car.id!!);
          setProcessing(false);
          navigate(!asTuning ? "/cars" : "/tuning");
        } catch (e) {
          console.log(e);
          toasts.push({
            title: t("Błąd"),
            content: t("Nie udało się zapisać"),
            show: true,
            id: generate(32),
            type: "danger",
          });
          setToasts([...toasts]);
          setProcessing(false);
        }
      }
    }
  };

  const changedCarModel = (e: any) => {
    if (e !== undefined && e !== null && e.length > 0) {
      setValue("carModel", (e[0] as CarModel).id!!);
    }
  };

  const changedCarBrand = (e: any) => {
    if (e !== undefined && e !== null && e.length > 0) {
      const newValue: string = (e[0] as CarBrand).id!!;
      setValue("carBrand", newValue);
      const rows = carModels.filter((it) => it.carBrand === newValue);
      setValue("carModel", "");
      setFilteredCarModels([...rows]);
      setCarModelsOptions([...rows]);
    }
  };

  const cancelFileUpload = (index: number) => {
    filesBeforeUpload.splice(index, 1);
    setFilesBeforeUpload([...filesBeforeUpload]);
  };

  const filterBy = () => true;

  const handleSearchYear = async (query: string) => {
    const rows = years.filter((it) => it.toString().indexOf(query) !== -1);
    setYearsOptions(rows.map((it) => ({year: it.toString()})));
  };

  const handleSearchCarGearBoxes = async (query: string) => {
    const rows = carGearBoxes.filter((it) => it.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    setCarGearBoxesOptions(rows);
  };

  const handleSearchCarTypes = async (query: string) => {
    const rows = carTypes.filter((it) => it.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    setCarTypesOptions(rows);
  };

  const handleSearchCarModels = async (query: string) => {
    const rows = filteredCarModels.filter((it) => it.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    setCarModelsOptions(rows);
  };

  const handleSearchCarBrand = async (query: string) => {
    const rows = carBrands.filter((it) => it.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    setCarBrandsOptions(rows);
  };

  const handleSearchClients = async (query: string) => {
    setIsLoadingClients(true);

    try {
      const rows = await listingSimpleClients(query);
      rows.forEach((row: any) => {
        row.title = `${row.companyName}, ${row.companyNip}`;
      });
      setClientsOptions(rows);
    } catch (e: any) {
      console.log(e);
    }

    setIsLoadingClients(false);
  };

  const changedClient = (e: any) => {
    if (e !== undefined && e !== null && e.length > 0) {
      setValue("company", (e[0] as Client).id!!);
    }
  };

  const changedCarType = (e: any) => {
    if (e !== undefined && e !== null && e.length > 0) {
      setValue("carType", (e[0] as CarType).id!!);
    }
  };

  return (
    <div className="row mb-5">
      <div className="col-12">
        <form onSubmit={handleSubmit(handleLogin)}>
          <div className="card shadow">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h4 className="header-title">{t("Tuning samochodu")}</h4>
              </div>
              <div className="row">
                {user !== undefined && user !== null && user.positionName !== "Partner" ? (
                  <div className="col-12 col-md-6 mb-4">
                    <div className="form-group position-relative">
                      <label className="form-label">{t("Firma")}</label>
                      <AsyncTypeahead
                        defaultInputValue={
                          car !== undefined && car !== null
                            ? `${car.Company?.companyName}, ${car.Company?.companyNip}`
                            : ""
                        }
                        filterBy={filterBy}
                        id="async-clients"
                        isLoading={isLoadingClients}
                        labelKey="title"
                        minLength={2}
                        onSearch={handleSearchClients}
                        onChange={(e) => changedClient(e)}
                        options={clientsOptions}
                        placeholder={t("Szukaj")}
                        renderMenuItemChildren={(option: any) => (
                          <>
                            <span>
                              {option.companyName}, {option.companyNip}
                            </span>
                          </>
                        )}
                      />
                      {errors && errors.company !== undefined ? (
                        <span className={"error-message"}>{t(errors.company?.type)}</span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div className="col-12 col-md-6 mb-4">
                  <div className="form-group position-relative">
                    <label className="form-label">{t("Typ pojazdu")}</label>
                    <AsyncTypeahead
                      defaultInputValue={car !== undefined && car !== null ? car.CarType?.title : ""}
                      filterBy={filterBy}
                      id="async-car-types"
                      isLoading={false}
                      labelKey="title"
                      minLength={0}
                      onSearch={handleSearchCarTypes}
                      onChange={(e) => changedCarType(e)}
                      // selected={car !== undefined && car !== null && carTypesOptions !== undefined && carTypesOptions != null && carTypesOptions.length > 0 ? [carTypesOptions.find(it => it.id === car.carType!!.toString()) as any] : []}
                      options={carTypesOptions}
                      placeholder={t("Wybierz typ pojazdu")}
                      renderMenuItemChildren={(option: any) => (
                        <>
                          <span>{option.title}</span>
                        </>
                      )}
                    />
                    {errors && errors.carType !== undefined ? (
                      <span className={"error-message"}>{t(errors.carType?.type)}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 mb-4">
                  <div className="form-group position-relative">
                    <label className="form-label">{t("Marka")}</label>
                    <AsyncTypeahead
                      defaultInputValue={car !== undefined && car !== null ? car.CarBrand?.title : ""}
                      filterBy={filterBy}
                      id="async-car-brand"
                      isLoading={false}
                      labelKey="title"
                      minLength={0}
                      onSearch={handleSearchCarBrand}
                      onChange={(e) => changedCarBrand(e)}
                      options={carBrandsOptions}
                      placeholder={t("Wybierz markę")}
                      renderMenuItemChildren={(option: any) => (
                        <>
                          <span>{option.title}</span>
                        </>
                      )}
                    />
                    {errors && errors.carBrand !== undefined ? (
                      <span className={"error-message"}>{t(errors.carBrand?.type)}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group position-relative">
                    <label className="form-label">{t("Model")}</label>
                    <AsyncTypeahead
                      defaultInputValue={car !== undefined && car !== null ? car.CarModel?.title : ""}
                      filterBy={filterBy}
                      id="async-car-model"
                      isLoading={false}
                      labelKey="title"
                      minLength={0}
                      onSearch={handleSearchCarModels}
                      onChange={(e) => changedCarModel(e)}
                      options={carModelsOptions}
                      placeholder={t("Wybierz model")}
                      renderMenuItemChildren={(option: any) => (
                        <>
                          <span>{option.title}</span>
                        </>
                      )}
                    />
                    {errors && errors.carModel !== undefined ? (
                      <span className={"error-message"}>{t(errors.carModel?.type)}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 mb-4">
                  <div className="form-group position-relative">
                    <label className="form-label">{t("Rok")}</label>
                    <AsyncTypeahead
                      defaultInputValue={car !== undefined && car !== null && car.year ? car.year!!.toString() : ""}
                      filterBy={filterBy}
                      id="async-year"
                      labelKey="year"
                      minLength={0}
                      // selected={car !== undefined && car !== null && yearsOptions !== undefined && yearsOptions != null && yearsOptions.length > 0 ? [yearsOptions.find(it => it.year === car.year!!.toString())] : []}
                      options={yearsOptions}
                      placeholder={t("Wybierz rok")}
                      isLoading={false}
                      onChange={(e) => setValue("year", (e[0] as any).year)}
                      onSearch={handleSearchYear}
                      renderMenuItemChildren={(option: any) => (
                        <>
                          <span>{option.year}</span>
                        </>
                      )}
                    />
                    {errors && errors.year !== undefined ? (
                      <span className={"error-message"}>{t(errors.year?.type)}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-6 mb-4">
                  <div className="form-group position-relative">
                    <label className="form-label">{t("Skrzynia biegów")}</label>
                    <AsyncTypeahead
                      defaultInputValue={car !== undefined && car !== null ? car.CarGearBox?.title : ""}
                      filterBy={filterBy}
                      id="async-car-gear"
                      isLoading={false}
                      labelKey="title"
                      minLength={0}
                      onSearch={handleSearchCarGearBoxes}
                      onChange={(e) => setValue("carGearBox", (e[0] as CarGearBox).id!!)}
                      // selected={car !== undefined && car !== null && carGearBoxesOptions !== undefined && carGearBoxesOptions != null && carGearBoxesOptions.length > 0 ? [carGearBoxesOptions.find(it => it.id === car.carGearBox!!.toString()) as any] : []}
                      options={carGearBoxesOptions}
                      placeholder={t("Wybierz skrzynię biegów")}
                      renderMenuItemChildren={(option: any) => (
                        <>
                          <span>{option.title}</span>
                        </>
                      )}
                    />
                    {errors && errors.carGearBox !== undefined ? (
                      <span className={"error-message"}>{t(errors.carGearBox?.type)}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 mb-4">
                  <div className="form-group position-relative">
                    <label className="form-label">{t("Moc")}</label>
                    <input
                      className="form-control"
                      id="input"
                      {...register("carPower", {required: true})}
                      defaultValue={""}
                    />
                    {errors && errors.carPower !== undefined ? (
                      <span className={"error-message"}>{t(errors.carPower?.type)}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-6 mb-4">
                  <div className="form-group position-relative">
                    <label className="form-label">{t("Numer rejestracyjny")}</label>
                    <input
                      className="form-control"
                      id="input"
                      {...register("registrationNumber", {required: true})}
                      defaultValue={""}
                    />
                    {errors && errors.registrationNumber !== undefined ? (
                      <span className={"error-message"}>{t(errors.registrationNumber?.type)}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 mb-4">
                  <div className="form-group position-relative">
                    <label className="form-label">{t("Numer VIN")}</label>
                    <input className="form-control" id="input" {...register("vin")} defaultValue={""} />
                    {errors && errors.vin !== undefined ? (
                      <span className={"error-message"}>{t(errors.vin?.type)}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-6 mb-4">
                  <div className="form-group position-relative">
                    <label className="form-label">{t("Euro")}</label>
                    <input className="form-control" id="input" {...register("euro")} defaultValue={""} />
                    {errors && errors.euro !== undefined ? (
                      <span className={"error-message"}>{t(errors.euro?.type)}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 mb-4">
                  <div className="form-group position-relative">
                    <label className="form-label">{t("Przebieg")}</label>
                    <input className="form-control" id="input" {...register("mileage")} defaultValue={""} />
                    {errors && errors.mileage !== undefined ? (
                      <span className={"error-message"}>{t(errors.mileage?.type)}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-6 mb-4">
                  <label className="form-label">&nbsp;</label>
                  <div className="form-group position-relative">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="sendSms"
                        {...register("sendSms")}
                        defaultChecked={car !== undefined && car !== null ? car.sendSms : true}
                      />
                      <label className="form-check-label" htmlFor="sendSms">
                        {t("Wysyłaj SMS")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-12 mb-4">
                  <div className={"row mb-4"}>
                    <div className="col-12 col-md-4">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="tuning"
                          {...register("withTuning")}
                          defaultValue={""}
                        />
                        <label className="form-check-label" htmlFor="tuning">
                          <img src={"/svg/tuning.svg"} className={"icon-tuning"} />
                          {t("Tuning")}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-check form-switch mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="tuningFix"
                          {...register("withTuningFix")}
                          defaultValue={""}
                        />
                        <label className="form-check-label" htmlFor="tuningFix">
                          {t("Poprawka")}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-check form-switch mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="tuningRevert"
                          {...register("withTuningRevert")}
                          defaultValue={""}
                        />
                        <label className="form-check-label" htmlFor="tuningRevert">
                          {t("Przywrócenie ORY")}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className={"row mb-4"}>
                    <div className="col-12 col-md-4">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="ograniczenia"
                          {...register("withLimitations")}
                          defaultValue={""}
                        />
                        <label className="form-check-label" htmlFor="ograniczenia">
                          <img src={"/svg/engine.svg"} className={"icon-tuning"} />
                          {t("Wyłączenie ograniczeń")}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-check form-switch mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="ograniczeniaFix"
                          {...register("withLimitationsFix")}
                          defaultValue={""}
                        />
                        <label className="form-check-label" htmlFor="ograniczeniaFix">
                          {t("Poprawka")}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-check form-switch mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="ograniczeniaRevert"
                          {...register("withLimitationsRevert")}
                          defaultValue={""}
                        />
                        <label className="form-check-label" htmlFor="ograniczeniaRevert">
                          {t("Przywrócenie ORY")}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className={"row mb-4"}>
                    <div className="col-12 col-md-4">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="adblue"
                          {...register("withAdBlue")}
                          defaultValue={""}
                        />
                        <label className="form-check-label" htmlFor="adblue">
                          <img src={"/svg/adblue.svg"} className={"icon-tuning"} />
                          {t("Zmniejszenie pobierania AdBLue")}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-check form-switch mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="adblueFix"
                          {...register("withAdBlueFix")}
                          defaultValue={""}
                        />
                        <label className="form-check-label" htmlFor="adblueFix">
                          {t("Poprawka")}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-check form-switch mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="adblueRevert"
                          {...register("withAdBlueRevert")}
                          defaultValue={""}
                        />
                        <label className="form-check-label" htmlFor="adblueRevert">
                          {t("Przywrócenie ORY")}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {user !== undefined && user !== null && user.positionName !== "Partner" ? (
                <>
                  <div className="row">
                    <div className="col-12 mb-4">
                      <span className="form-label d-block">{t("Faktury")}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-4 mb-4">
                      <div className="form-group position-relative">
                        <label className="form-label">{t("Numer faktury")}</label>
                        <input className="form-control" id="input" {...register("invoice")} defaultValue={""} />
                        {errors && errors.invoice !== undefined ? (
                          <span className={"error-message"}>{t(errors.invoice?.type)}</span>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4 mb-4">
                      <div className="form-group position-relative d-flex flex-column align-items-start">
                        <label className="form-label">&nbsp;</label>
                        <a className={'btn btn-primary'} onClick={addInvoice}>{t('Dodaj')}</a>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4 mb-4">
                      {invoices.map((it: any, key: number) => (
                        <div key={`InvoiceToDelete${key}`} onClick={() => removeInvoice(it, key)} className={`mb-2 ${it.toDelete ? 'hidden' : ''}`}>
                          <i className={'mdi mdi-delete mr-3 text-danger hoverable'}></i>
                          <span>{it.invoice}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              ) : <></>}
              <div className="row">
                <div className="col-12 mb-4">
                  <span className="form-label d-block">{t("Dodaj plik")}</span>
                  <label {...getRootProps({className: "dropzone"})} className="dropzone">
                    <div className="dz-message">
                      <i className="icon mdi mdi-cloud-upload-outline"></i>
                      <h4 className="text">{t("Upuść plik lub kliknij żeby wgrać")}</h4>
                    </div>
                    <input className="d-none" type="file" id="formFile" />
                  </label>
                  <div className="dropzone-previews mt-3">
                    {filesBeforeUpload.map((file: any, index: number) => (
                      <div className="card mt-1 mb-0 shadow-none border" key={`FileBeforeUpload${index}`}>
                        <div className="p-2">
                          <div className="row align-items-center">
                            <div className="col-auto">
                              <div className="avatar-sm">
                                <span className="avatar-title rounded">
                                  <i className="mdi mdi-file font-16"></i>
                                </span>
                              </div>
                            </div>
                            <div className="col ps-0">
                              <a className="file_name text-muted fw-bold" data-dz-name="">
                                {file.path}
                              </a>
                              <p className="mb-0 file_name" data-dz-size="">
                                <strong>{(file.size / 1024 / 1024).toFixed(2)}</strong>MB
                              </p>
                            </div>
                            <div className="col-auto">
                              <a
                                onClick={() => cancelFileUpload(index)}
                                className="btn btn-link btn-lg text-muted"
                                data-dz-remove=""
                              >
                                <i className="mdi mdi-close"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 mb-4 text-center">
                  <button className="btn btn-primary btn-lg w-100">
                    {processing ? <img src={"/svg/loader.svg"} className={"btn-loader"} /> : <></>}
                    {t(!processing ? "Wyślij" : "Proszę czekać")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
