import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "./../hooks/use-auth";
import { useTranslation } from "react-i18next";
import { UserContext } from "../hooks/user-context";

export default function ForgetPassword() {
  const { user, setUserContext } = useContext(UserContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { loginUser, error }: any = useAuth();

  React.useEffect(() => {
    if (user !== undefined && user !== null) {
      navigate("/home");
    }
  }, []);

  return (
    <div className="wrapper">
      <div className="container">
        <div className="row mb-5 d-flex justify-content-center">
          <div className="col-12 col-md-6 col-lg-5">
            <div className="card shadow mt-5">
              <div className="bg-light text-center py-5">
                <img src={"/svg/favicon.svg"} className={"w-25 mb-4"} />
                <h4 className="header-title">{t("Zapomniałeś hasła do Chip Tuning Menagera")}</h4>
              </div>
              <div className="card-body">
                <form className="">
                  <div className="row">
                    <div className="col-12 mb-4">
                      <div className="">
                        <label className="form-label">{t("Nazwa / E-mail")}</label>
                        <input className="form-control" id="input" type="text" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 mb-4 text-center">
                      <button className="btn btn-primary btn-lg w-100">{t("Wyślij")}</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
