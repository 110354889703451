import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import { LayoutContext } from "../hooks/layout-context";
import styled from "@emotion/styled";
import { UserContext } from "../hooks/user-context";
import useCar from "../hooks/use-car";
import { DashboardStats } from "../types/car";
import moment from "moment";

const ImageAvatarStyled = styled("img")`
  height: 100px;
`;

export default function Home() {
  const { t } = useTranslation();
  const { layout, setLayout } = useContext(LayoutContext);
  const { user, isLoading } = useContext(UserContext);
  const { getDashboardStats } = useCar();
  const [stats, setStats] = useState<DashboardStats>({
    carsYearCurrent: 0,
    carsMonthCurrent: 0,
    carsWeekCurrent: 0,
    carsYearPrevious: 0,
    carsMonthPrevious: 0,
    carsWeekPrevious: 0,
    yearPercentage: 0,
    monthPercentage: 0,
    weekPercentage: 0,
  });

  const getMonthName = () => {
    const month = parseFloat(moment().format("M"));
    switch (month) {
      case 1:
        return "styczniu";
        break;
      case 2:
        return "lutym";
        break;
      case 3:
        return "marcu";
        break;
      case 4:
        return "kwietniu";
        break;
      case 5:
        return "maju";
        break;
      case 6:
        return "czerwcu";
        break;
      case 7:
        return "lipcu";
        break;
      case 8:
        return "sierpniu";
        break;
      case 9:
        return "wrześniu";
        break;
      case 10:
        return "październiku";
        break;
      case 11:
        return "listopadzie";
        break;
      case 12:
        return "grudniu";
        break;
    }

    return "";
  };

  React.useEffect(() => {
    layout.sideMenu = "home";
    layout.breadcrumbs = [];
    setLayout({ ...layout });

    (async () => {
      try {
        const res = await getDashboardStats();
        setStats(res);
      } catch (e: any) {
        console.log(e);
      }
    })();
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("Home")}</title>
      </Helmet>
      <div className="row mb-5">
        <div className="col-xxl-3 col-sm-6 mb-4">
          <div className="card shadow h-100">
            <div className="card-body">
              <span className="float-start m-2 me-4">
                <ImageAvatarStyled src="/svg/avatar-person.svg" className="rounded-circle img-thumbnail" />
              </span>
              <div className="">
                <h4 className="mt-1 mb-1">{user ? user.firstName : ""}</h4>
                <p className="font-13"> {user ? user.positionName : ""}</p>
              </div>
            </div>
          </div>
        </div>

        {!isLoading && user !== undefined && user !== null && user.permissions.indexOf("cars.stats") !== -1 ? (
          <>
            <div className="col-xxl-3 col-sm-6 mb-4">
              <div className="card widget-flat bg-success bg-gradient text-white h-100 shadow">
                <div className="card-body">
                  <div className="float-end">
                    <i className="mdi mdi-car-3-plus widget-icon text-white"></i>
                  </div>
                  <h6 className="text-uppercase mt-0">{t("Wszystkie pojazdy")}</h6>
                  <h3 className="mt-3 mb-3">{stats.carsYearCurrent}</h3>
                  <p className="mb-0">
                    <span className="badge badge-light-lighten me-1">
                      <i
                        className={`mdi ${stats.yearPercentage > 0 ? "mdi-arrow-up-bold" : "mdi-arrow-down-bold"}`}
                      ></i>{" "}
                      {Math.abs(stats.yearPercentage).toFixed(2)}%
                    </span>
                    <span className="text-nowrap">{t("Do ubiegłego roku")}</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-sm-6 mb-4">
              <div className="card widget-flat bg-primary bg-primary-subtle bg-gradient text-white h-100 shadow">
                <div className="card-body">
                  <div className="float-end">
                    <i className="mdi mdi-car-2-plus widget-icon rounded-circle text-white"></i>
                  </div>
                  <h6 className="text-uppercase mt-0">
                    {t("Pojazdy w")}: {getMonthName()}
                  </h6>
                  <h3 className="mt-3 mb-3 text-white">{stats.carsMonthCurrent}</h3>
                  <p className="mb-0">
                    <span className="badge bg-info me-1">
                      <i
                        className={`mdi ${stats.monthPercentage > 0 ? "mdi-arrow-up-bold" : "mdi-arrow-down-bold"}`}
                      ></i>{" "}
                      {Math.abs(stats.monthPercentage).toFixed(2)}%
                    </span>
                    <span className="text-nowrap">{t("Do ubiegłego miesiąca")}</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-sm-6 mb-4">
              <div className="card widget-flat bg-warning bg-gradient text-white h-100 shadow">
                <div className="card-body">
                  <div className="float-end">
                    <i className="mdi mdi-car-2-plus widget-icon rounded-circle text-white"></i>
                  </div>
                  <h6 className="text-uppercase mt-0">{t("Pojazdy w tym tygodniu")}</h6>
                  <h3 className="mt-3 mb-3 text-white">{stats.carsWeekCurrent}</h3>
                  <p className="mb-0">
                    <span className="badge bg-black me-1">
                      <i
                        className={`mdi ${stats.weekPercentage > 0 ? "mdi-arrow-up-bold" : "mdi-arrow-down-bold"}`}
                      ></i>{" "}
                      {Math.abs(stats.weekPercentage).toFixed(2)}%
                    </span>
                    <span className="text-nowrap">{t("Do ubiegłego tygodnia")}</span>
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
