import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { LayoutContext } from "../../hooks/layout-context";
import { Link } from "react-router-dom";
import { Employee } from "../../types/employee";
import useEmployee from "../../hooks/use-employee";
import DataTable from "react-data-table-component";
import { Dropdown } from "react-bootstrap";
import Confirmation from "../../components/layout/confirmation";
import { generate } from "../../lib/random-id-lib";
import { ToastsContext } from "../../hooks/toasts-context";
import { UserContext } from "../../hooks/user-context";

export default function EmployeeList() {
  const { t } = useTranslation();
  const { layout, setLayout } = useContext(LayoutContext);
  const [rows, setRows] = useState<Array<Employee>>([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [showTable, setShowTable] = useState(false);
  const { listing, updateEmployee, forceDeleteEmployee } = useEmployee();
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(null);
  const [selectedEmployeeDelete, setSelectedEmployeeDelete] = useState<Employee | null>(null);
  const { toasts, setToasts } = useContext(ToastsContext);
  const { user, isLoading } = useContext(UserContext);
  const [search, setSearch] = useState<string>("");
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);

  React.useEffect(() => {
    layout.sideMenu = "employee";
    layout.breadcrumbs = [{ path: null, label: t("Pracownicy") }];
    setLayout({ ...layout });
  }, []);

  const columns: Array<any> = [
    {
      name: "",
      selector: (row: any) =>
        row.pathThumb !== undefined && row.pathThumb !== null ? (
          <span className={"user-avatar"}>
            <img src={row.pathThumb} />
          </span>
        ) : (
          <span className={"user-avatar"}>{row.initial}</span>
        ),
      maxWidth: "50px",
      minWidth: "50px",
      right: false,
    },
    {
      name: t("Nazwa"),
      sortable: true,
      key: "firstName",
      selector: (row: any) => <span>{row.firstName}</span>,
      right: false,
    },
    {
      name: t("Email"),
      sortable: true,
      key: "email",
      selector: (row: any) => <span>{row.email}</span>,
      right: false,
    },
    {
      name: t("Stanowisko"),
      sortable: true,
      key: "positionName",
      selector: (row: any) => <span>{t(row.positionName === "Tuning" ? "Technik" : row.positionName)}</span>,
      right: false,
    },
    {
      name: "",
      selector: (row: any) => (
        <Dropdown>
          <Dropdown.Toggle as={"a"}>
            <i className="mdi mdi-dots-vertical"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item as={"div"}>
              <Link to={`/employee/edit/${row.id}`}>{t("Edytuj")}</Link>
            </Dropdown.Item>
            {row.active ? (
              <Dropdown.Item onClick={() => setSelectedEmployee(row)}>{t("Zablokuj")}</Dropdown.Item>
            ) : (
              <Dropdown.Item onClick={() => setSelectedEmployee(row)}>{t("Odblokuj")}</Dropdown.Item>
            )}
            <Dropdown.Item onClick={() => setSelectedEmployeeDelete(row)}>{t("Usuń")}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
      right: true,
    },
  ];

  const handlePageChange = async (page: number) => {
    setLoading(true);
    await loadListing(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setLoading(true);
    await loadListing(page, newPerPage);
    setPerPage(newPerPage);
  };

  const loadListing = async (
    page: number,
    currentPerPage: number,
    sField: string | null = null,
    sDirection: string | null = null
  ) => {
    try {
      const result = await listing(page, currentPerPage, search, sField, sDirection);
      setRows(result.data);
      setTotalRows(result.page.totalElements);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
    if (!showTable) {
      setShowTable(true);
    }
  };

  const deleteEmployee = async (id: any) => {
    console.log("deleteEmployee", id);
    if (selectedEmployeeDelete !== undefined && selectedEmployeeDelete !== null) {
      try {
        await forceDeleteEmployee(selectedEmployeeDelete.id!!);
        toasts.push({
          title: t("Sukces"),
          content: t("Pracownik usunięty"),
          show: true,
          id: generate(32),
          type: "success",
        });
        setToasts([...toasts]);
      } catch (e) {
        console.log(e);
        toasts.push({
          title: t("Błąd"),
          content: t("Nie udało się usunąć"),
          show: true,
          id: generate(32),
          type: "danger",
        });
        setToasts([...toasts]);
      }

      await loadListing(1, perPage);
    }

    setSelectedEmployeeDelete(null);
  };

  React.useEffect(() => {
    (async () => {
      await loadListing(1, perPage);
    })();
  }, [search]);

  const changeEmployeeState = async () => {
    console.log("changeEmployeeState", selectedEmployee);
    if (selectedEmployee !== undefined && selectedEmployee !== null) {
      try {
        await updateEmployee(selectedEmployee.id!!, { active: !selectedEmployee.active });
        toasts.push({ title: t("Sukces"), content: t("Dane zapisane"), show: true, id: generate(32), type: "success" });
        setToasts([...toasts]);
      } catch (e) {
        console.log(e);
        toasts.push({
          title: t("Błąd"),
          content: t("Nie udało się zapisać"),
          show: true,
          id: generate(32),
          type: "danger",
        });
        setToasts([...toasts]);
      }

      try {
        const index = rows.findIndex((it) => it.id === selectedEmployee.id!!);
        if (index !== -1) {
          rows[index].active = !rows[index].active;
        }
        setRows([...rows]);
      } catch (e) {
        console.log(e);
      }
    }

    setSelectedEmployee(null);
  };

  const handleSort = async (column: any, direction: any) => {
    console.log("column", column, "direction", direction);
    setLoading(true);
    setSortField(column.key);
    setSortDirection(direction);
    await loadListing(1, perPage, column.key, direction);
    setLoading(false);
  };

  return (
    <div className="row mb-5">
      <div className="col-12">
        <div className="card shadow">
          <div className="card-body">
            <div className="row">
              <div className="col-sm-6 mb-4">
                {!isLoading &&
                user !== undefined &&
                user !== null &&
                user.permissions.indexOf("employee.add") !== -1 ? (
                  <Link to={"/employee/add"} className="btn btn-primary mb-2">
                    <i className="mdi mdi-plus-circle me-2"></i>
                    {t("Dodaj pracownika")}
                  </Link>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-sm-6">
                <div className="data-tables-filter text-end">
                  <label>
                    {t("Szukaj")}:
                    <input
                      type="search"
                      className="form-control form-control-sm"
                      placeholder=""
                      onChange={(e) => setSearch(e.target.value)}
                      value={search || ""}
                      name={"search"}
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              {showTable ? (
                <DataTable
                  conditionalRowStyles={[{ when: (row) => !row.active, classNames: ["row-disabled"] }]}
                  columns={columns}
                  data={rows}
                  striped={false}
                  sortServer
                  onSort={handleSort}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  noDataComponent={<div className={"p5"}>{t("Brak pracowników do wyświetlenia")}</div>}
                  paginationComponentOptions={{
                    rowsPerPageText: t("Wyświetl pracowników na stronę:"),
                    rangeSeparatorText: t("of"),
                    noRowsPerPage: false,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: t("All"),
                  }}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                />
              ) : (
                <></>
              )}
              <Confirmation
                showConfirmation={selectedEmployee !== null}
                onClose={() => setSelectedEmployee(null)}
                onConfirm={() => changeEmployeeState()}
                content={
                  selectedEmployee?.active === true
                    ? t("Czy na pewno chcesz zablokować pracownika?")
                    : t("Czy na pewno chcesz odblokowć pracownika?")
                }
              />
              <Confirmation
                showConfirmation={selectedEmployeeDelete !== null}
                onClose={() => setSelectedEmployeeDelete(null)}
                onConfirm={() => deleteEmployee(selectedEmployeeDelete!!.id)}
                content={t("Czy na pewno chcesz usunąć pracownika?")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
