import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import { LayoutContext } from "../../hooks/layout-context";
import { Link } from "react-router-dom";
import { UserContext } from "../../hooks/user-context";

export default function Settings() {
  const { t } = useTranslation();
  const { layout, setLayout } = useContext(LayoutContext);
  const { user, isLoading } = useContext(UserContext);

  React.useEffect(() => {
    layout.sideMenu = "settings";
    layout.breadcrumbs = [];
    setLayout({ ...layout });
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("Ustawienia")}</title>
      </Helmet>
      {!isLoading && user !== undefined && user !== null ? (
        <div className="row mb-5">
          {user.permissions.indexOf("settings.carType") !== -1 ? (
            <div className="col-xxl-3 col-sm-6 mb-4">
              <Link to={"/settings/car/type"} className="card shadow text-decoration-none">
                <div className="card-body py-5">
                  <div className="d-block text-center text-decoration-none text-black-50">
                    <span className={"mdi mdi-truck-outline setting-icon text-primary"}></span>
                    <h4 className="mt-1 mb-1">{t("Rodzaj pojazdu")}</h4>
                  </div>
                </div>
              </Link>
            </div>
          ) : (
            <></>
          )}
          {user.permissions.indexOf("settings.carBrand") !== -1 ? (
            <div className="col-xxl-3 col-sm-6 mb-4">
              <Link to={"/settings/car/brand"} className="card shadow text-decoration-none">
                <div className="card-body py-5">
                  <div className="d-block text-center text-decoration-none text-black-50">
                    <span className={"mdi mdi-truck-check-outline setting-icon  text-primary"}></span>
                    <h4 className="mt-1 mb-1">{t("Marka pojazdu")}</h4>
                  </div>
                </div>
              </Link>
            </div>
          ) : (
            <></>
          )}
          {user.permissions.indexOf("settings.carModel") !== -1 ? (
            <div className="col-xxl-3 col-sm-6 mb-4">
              <Link to={"/settings/car/model"} className="card shadow text-decoration-none">
                <div className="card-body py-5">
                  <div className="d-block text-center text-decoration-none text-black-50">
                    <span className={"mdi mdi-dump-truck setting-icon  text-primary"}></span>
                    <h4 className="mt-1 mb-1">{t("Model pojazdu")}</h4>
                  </div>
                </div>
              </Link>
            </div>
          ) : (
            <></>
          )}
          {user.permissions.indexOf("settings.gearBox") !== -1 ? (
            <div className="col-xxl-3 col-sm-6 mb-4">
              <Link to={"/settings/car/gear/box"} className="card shadow text-decoration-none">
                <div className="card-body py-5">
                  <div className="d-block text-center text-decoration-none text-black-50">
                    <span className={"mdi mdi-car-shift-pattern setting-icon text-primary"}></span>
                    <h4 className="mt-1 mb-1">{t("Skrzynia biegów")}</h4>
                  </div>
                </div>
              </Link>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
